<template>
  <div class="home-header-right_box">
    <div class="right-list_box">
      <!-- <div class="right_link">
        <div
          class="link cp"
          @mouseover="activeC = 2"
          @mouseleave="activeC = 0"
          :class="activeC == 2 ? 'select' : ''"
          @click="handle_activeC(2)"
        >
          鹰击
        </div>
        <div
          class="link cp"
          @mouseover="activeC = 3"
          @mouseleave="activeC = 0"
          :class="activeC == 3 ? 'select' : ''"
          @click="handle_activeC(3)"
        >
          鹰训
        </div>
      </div> -->
      <!-- <el-tooltip class="cp" content="飞行检查" placement="bottom">
        <div class="list-item_box" @click="checkVisible = true">
          <div class="icon">
            <img src="~@/assets/images/webhome/safe.png" alt="" />
          </div>
        </div>
      </el-tooltip> -->
      <!-- <div class="list-item_box cp" @click="setPoliceWorkLevel">
        <div class="icon">
          <img
            v-if="type == 1"
            src="~@/assets/images/webhome/pingshi.png"
            alt=""
          />
          <img v-else src="~@/assets/images/webhome/zhanshi.png" alt="" />
        </div>
      </div> -->
      <!-- <el-tooltip class="cp" content="消息通知444" placement="bottom">
        <div class="list-item_box chat" @click="openmessagebox">
          <div class="icon" style="padding-left: 5px">
            <img
              src="~@/assets/new_yingshi/xiaoxi.png"
              class="yingimg"
              alt=""
            />
          </div>
          <div class="text">
            <span class="yuandian" v-if="number > 0"></span>
          </div>
        </div>
      </el-tooltip> -->

      <el-tooltip class="cp" content="消息通知" placement="bottom">
        <div class="list-item_box chat" @click="openmessagebox">
          <div class="Popout">
            <div class="Popout_bj"></div>
            <div class="icon">
              <img src="~@/assets/zong_lan_ye_img/icon_消息通知.png" style="width:22px;height:24px" alt="">
            </div>
            <div class="fontFive pa left17">消息通知 </div>
          </div>
          <div class="text">
            <!-- <span class="yuandian" v-if="number > 0"></span> -->
          </div>
        </div>
      </el-tooltip>
      <Message @handleClose="showmessage = false" @numbeichange="numbergb" v-show="showmessage"></Message>
      <!-- <div class="list-item_box duty cp">
        <el-dropdown trigger="click"
                     ref="dropdown">
          <div class="icon"
               style="padding-left: 5px">
            <div class="iconfont icon-daka"></div>
          </div>
          <div class="text"
               style="color: #00d2ff">报备情况</div>
          <el-dropdown-menu slot="dropdown">
            <div class="duty-list-box"
                 style="width: 547px">
              <div class="search-box">
                <el-input v-model="filter.name"
                          clearable
                          placeholder="请输入姓名"></el-input>
                <div @click="handleChange(0)">
                  <el-cascader v-model="filter.orgId"
                               :options="options"
                               clearable
                               :show-all-levels="false"
                               placeholder="请选择单位"
                               :props="{
                      children: 'children',
                      label: 'orgName',
                      value: 'id',
                      checkStrictly: true,
                      emitPath: false,
                    }"
                               @change="handleChange(1)"
                               ref="cascader"></el-cascader>
                </div>
                <div class="hoverbgcolor"
                     @click="getCheckList">
                  <el-tooltip content="搜索"
                              placement="top">
                    <img src="@/assets/images/ssdj.svg"
                         alt="" />
                  </el-tooltip>
                </div>
                <div class="hoverbgcolor"
                     @click="handle_report_show(true)">
                  <el-tooltip content="资源报备"
                              placement="top">
                    <img src="@/assets/images/zybb.svg"
                         alt="" />
                  </el-tooltip>
                </div>
                <div class="hoverbgcolor"
                     @click="handle_detail">
                  <el-tooltip content="人员管理"
                              placement="top">
                    <img src="@/assets/images/xq.svg"
                         alt="" />
                  </el-tooltip>
                </div>
              </div>
              <div class="hd_box">
                <div class="tr">
                  <div class="th">人员姓名</div>
                  <div class="th">警员编号</div>
                  <div class="th">所属单位</div>
                  <div class="th">联系方式</div>
                  <div class="th">打卡次数</div>
                  <div class="th">未打卡次数</div>
                </div>
              </div>
              <div class="bd_box bd_box_card">
                <div class="tr"
                     v-for="(item, index) in list_check_arr"
                     :key="index">
                  <div class="td">{{ item.name }}</div>
                  <div class="td">{{ item.siren }}</div>
                  <div class="td">{{ item.orgName }}</div>
                  <div class="td">{{ item.phone }}</div>
                  <div class="td">{{ item.count }}</div>
                  <div class="td">{{ item.noCount }}</div>
                </div>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->

      <!-- 报表导出 -->
      <!-- <div class="list-item_box cp"
           @click="tableVisible = true">
        <div class="icon">
          <div><img src="@/assets/images/export.png" alt=""></div>
        </div>
        <div class="text cp"
             style="color: #00d2ff">报表导出</div>
      </div> -->

      <!-- <div class="list-item_box chat"
           @click="link_chat">
        <div class="icon"
             style="padding-left: 5px">
          <div class="iconfont icon-zaixianzixun1"></div>
        </div>
        <div class="text">在线咨询</div>
      </div> -->
      <div class="list-item_box chat" @click="handle_logout">
        <div class="Popout">
          <div class="Popout_bj"></div>
          <div class="icon">
            <img src="~@/assets/new_yingshi/usr.png" style="width:22px;height:24px" alt="">
          </div>
          <div class="fontFive pa lh25 wih100 tc ml7">{{ user_info.name }}</div>
        </div>
        <div class="text">
          <!-- <span class="yuandian" v-if="number > 0"></span> -->
        </div>
      </div>
    </div>

    <div class="isdialogcss" v-show="ismroedialog">
      <div v-interact class="isdialogclose">
        <div class="isdialogmes">订阅更多信息</div>
        <img src="~@/assets/images/accident/close.png" class="img-close" @click="handle_close" />
      </div>
      <div v-for="item in sizeForm" :key="item.id" class="isdialoglist">
        <div class="manylistcss">
          <!-- 未订阅 -->
          <div v-if="item.messageName == '任务审批'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noTaskCount
            }}</span>
          </div>
          <div v-if="item.messageName == '打卡提醒'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noNotificationCount
            }}</span>
          </div>
          <div v-if="item.messageName == '空域权限'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noApplyCount
            }}</span>
          </div>
          <div v-if="item.messageName == '调度审批'" class="leftmany">
            <span style="margin-right: 5px">{{ item.messageName }}</span>
            <span v-show="item.messageStatus == 0" class="numbercss">{{
              Digitaltipdata.noApprovalCount
            }}</span>
          </div>

          <div v-if="item.messageName == '任务审批'" class="rightmany">
            <el-checkbox class="checkboxaaa" disabled v-model="item.checked" style="margin-right: 5px">订阅</el-checkbox>
          </div>
          <div v-else class="rightmany">
            <el-checkbox class="checkboxaaa" v-model="item.checked" style="margin-right: 5px"
              @change="btnswitch(item)">订阅</el-checkbox>
          </div>
        </div>
      </div>
    </div>

    <!-- 任务详情弹框 -->
    <div class="routesdialog" v-show="isTaskdetails">
      <div v-interact class="routesbox">
        <div class="routestext">任务详情</div>
        <img src="~@/assets/images/accident/close.png" class="img-close" @click="task_close" />
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">任务名称:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.taskTitle }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">航线名称:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.flightName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">任务类别:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.taskCateName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">任务类型:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.taskTypeName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">责任单位:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.organizationName || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">飞手:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.flyNames || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">电池编号:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.batteryNumbers || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">预计开始时间:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.startedAt || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">内容描述:</div>
        <div class="tworoutes" style="width: 70%">
          {{ Taskdetailslist.remark || "暂无" }}
        </div>
      </div>
    </div>

    <!-- 航线详情弹框 -->
    <div class="routesdialog" v-show="isroutesdialog">
      <div v-interact class="routesbox">
        <div class="routestext">航线详情</div>
        <img src="~@/assets/images/accident/close.png" class="img-close" @click="routes_close" />
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">航线名称:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.flightName }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">目标位置:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.destination || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">航线速度:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.speed + "(m/s)" || "暂无" }}
        </div>
      </div>
      <div class="routeslistcss">
        <div class="oneroutes" style="width: 30%">高度:</div>
        <div class="tworoutes" style="width: 70%">
          {{ routesList.averageHeight + "(m)" || "暂无" }}
        </div>
      </div>
    </div>
    <!-- <div class="securityFlyDialog" v-if="securityFlyVisible">
      <div class="security_head">
        <div class="security_head_left">安全飞行注意事项</div>
        <div class="security_head_right" @click="security">关闭</div>
      </div>
      <div class="security_content">
        <div
          class="security_content_symbol"
          v-for="item in securityList"
          :key="item.lightInfoId"
        >
          {{ item.detail }}
        </div>
      </div>
    </div> -->
    <!-- 审批弹框 -->
    <el-dialog :show-close="false" center title="审批" :close-on-click-modal="false" :visible.sync="approve_form.visible"
      :append-to-body="true" top="20em" width="30%">
      <div>
        <div class="w20 h20 pa top20 right20 cp" @click="approve_form.visible = false">
          <img class="dib wih100 ht100" src="~@/assets/images/close.png" alt="" />
        </div>
        <el-form label-width="80px">
          <el-form-item label="备注">
            <el-input v-model="approve_form.approvalRemark" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span v-if="this.active == 3" slot="footer" style="display: flex; justify-content: center">
        <div class="jcsb">
          <div @click="handle_approve(2)" class="w114 h40 lh40 cf f22 cp mr40" style="background-color: #c24848">
            不通过
          </div>
          <div @click="handle_approve(1)" class="w114 h40 lh40 cf f22 cp ml40" style="background-color: #129c9c">
            通过
          </div>
        </div>
      </span>
      <span v-else slot="footer" style="display: flex; justify-content: center">
        <div class="jcsb">
          <div @click="handle_approve(0)" class="w114 h40 lh40 cf f22 cp mr40" style="background-color: #c24848">
            不通过
          </div>
          <div @click="handle_approve(1)" class="w114 h40 lh40 cf f22 cp ml40" style="background-color: #129c9c">
            通过
          </div>
        </div>
      </span>
    </el-dialog>

    <SafeFly :securityFlyVisible="FlyVisible" @close="FlyVisible = false" ref="SafeFlyRef" />
    <!-- 安全检查 -->
    <!-- <checkSafe @close="checkVisible = false" v-if="checkVisible"></checkSafe> -->
  </div>
</template>

<script>
import API from "@/api";
import checkSafe from "./check-safe.vue";
import SafeFly from "./safeFly.vue";
import Message from "@/pages/home/Confirmation_Dialogs/inform";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    activeC: {
      type: Number,
      default: () => 0,
    },
  },
  components: {
    checkSafe,
    SafeFly,
    Message,
  },
  computed: {
    weather () {
      return this.g_weather();
    },
    //全部总和
    totalListLength () {
      return (
        this.Digitaltipdata.taskCount +
        this.Digitaltipdata.notificationCount +
        this.Digitaltipdata.applyCount +
        this.Digitaltipdata.approvalCount +
        this.Digitaltipdata.totalNoCount
      );
    },
    ...mapGetters(["user_info"]),
  },
  data () {
    return {
      number: null,
      // 消息提示框
      showmessage: false,
      //首页提示框
      Promptbox: true,
      // 报表导出
      tableVisible: false,
      FlyVisible: false,
      // 安全检查
      checkVisible: true,
      //调度审批数据
      schedullist: [],
      //数字提示
      Digitaltipdata: [],
      //任务详情数据
      Taskdetailslist: [],
      //任务详情弹框
      isTaskdetails: false,
      //航线详情数据
      routesList: [],
      //航线详情弹框是否关闭
      isroutesdialog: false,
      //更多弹框的是否关闭
      ismroedialog: false,
      //更多列表的数据
      sizeForm: [],

      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      // 更多信息的页签头
      tagnamelist: [],
      check_list: [],
      airspace_list: [],
      map: {
        type: {
          0: "申报",
          1: "报备",
        },
      },
      active: 0,
      pdata: {},
      data: {},
      // list: {
      //   duty: [],
      //   check: [],
      // },
      filter: {
        name: null,
        orgId: null,
      },
      options: [],
      list_duty_arr: [],
      list_check_arr: [],
      securityList: [],
      securityFlyVisible: false,
      type: 1,
      id: null,
    };
  },
  async mounted () {
    let flag = localStorage.getItem("flag")
      ? localStorage.getItem("flag")
      : JSON.parse(localStorage.getItem("user_info")).data.checkStatus;
    if (flag == 1) {
      this.checkVisible = false;
    } else {
      this.checkVisible = true;
    }
    this.id = this.user_info.departmentId;
    this.getPoliceWorkLevel();

    // title
    this.arilist();
    await this.getOptions();
    await this.getCheckList();
    await this.get_duty();
    await this.list_duty();
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    init_ws () {
      const data = new Date();
      let time = data.getHours();
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      let ws = new WebSocket(ws_url_al);
      let _this = this;
      ws.onopen = () => {
        console.log("建立连接");
      };

      ws.onmessage = (e) => {
        let metadata = JSON.parse(e.data);
        // console.log(metadata, '无人机原始数据，可能引起地图压力过大14');
        if (metadata.msgnum === 4134) {
          // 临时禁飞区
        } else if (metadata.msgnum == 4133) {
          // this.g_cesium_resetJfq()
        }
      };

      ws.onerror = (error) => {
        //console.log("error", error);
      };

      ws.onclose = (data) => {
        console.log("onclose", data);
      };
    },
    handle_activeC (val) {
      this.activeC = val;
      this.$emit("changactive", val);
    },
    numbergb (num) {
      this.number = num;
    },
    ...mapActions("user", ["Logout"]),
    checkSafe () { },
    //打开消息提示框
    openmessagebox () {
      this.showmessage = true;
    },
    async setPoliceWorkLevel () {
      if (this.id != 1) {
        return;
      } else {
        if (this.type == 1) {
          this.type = 2;
        } else {
          this.type = 1;
        }
        let res = await API.HOME.setPoliceWorkLevel({
          id: 1,
          type: this.type,
        });
      }
    },
    async getPoliceWorkLevel () {
      let res = await API.HOME.getPoliceWorkLevel();
      this.type = res.type;
    },
    async getCheckList () {
      // let res = await API.PERSON.CheckInfo(this.filter);
      // this.list_check_arr = res || [];
      this.list.check =  [];
    },
    async securityFly () {
      this.FlyVisible = true;
      this.$refs.SafeFlyRef && this.$refs.SafeFlyRef.securityFly();
      // let res = await API.SECURITYFLY.Security(3);
      // this.securityList = res;
    },
    handle_detail () {
      let { href } = this.$router.resolve({ path: "/command?page=6" });
      window.open(href, "_blank");
    },
    async get_duty () {
      let data = await API.USER.GetDuty();

      this.pdata = data;
    },
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async list_duty () {
      let data = await API.USER.ListDuty();
      this.list_duty_arr = data || [];
    },

    handle_active (e) {
      this.active = e;
    },
    //数字提示
    async upDigitaltip () {
      this.Digitaltipdata = await API.USER.Digitaltip();
    },
    //任务详情按钮
    async btnTaskdetails (id) {
      this.isTaskdetails = true;
      let res = await API.USER.detailslist(id);
      this.Taskdetailslist = res || [];
    },
    //航线详情按钮
    async btnRoutesList (id) {
      this.isroutesdialog = true;
      let res = await API.USER.routesListqq(id);
      this.routesList = res || [];
    },
    //对√得切换
    async btnswitch (item) {
      if (item.messageStatus == 1) {
        item.status = 0;
      } else if (item.messageStatus == 0) {
        item.status = 1;
      }
      await API.USER.issubscribe(item);
      this.arilist();
      this.btnMore();
    },
    //更多的点击按钮
    async btnMore () {
      this.ismroedialog = true;
      let res = await API.USER.ManyBtnList();
      const data = res.map((item) => {
        if (item.messageStatus == 1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      });
      this.sizeForm = data || [];
    },
    //左点击滑动按钮
    handClickleft () {
      this.handle_active(this.tagnamelist[this.tagnamelist.length - 1].id - 1);
      // //删除最后一个并添加到第一个
      this.tagnamelist.unshift(this.tagnamelist.pop());
    },
    //右点击按钮
    handClickright () {
      this.tagnamelist.push(this.tagnamelist.shift());
      this.handle_active(this.tagnamelist[0].id - 1);
    },
    //更多的关闭按钮
    handle_close () {
      this.ismroedialog = false;
    },
    //任务详情关闭按钮
    task_close () {
      this.isTaskdetails = false;
    },
    // 航线详情的关闭按钮
    routes_close () {
      this.isroutesdialog = false;
    },
    ...mapActions("user", ["Logout"]),
    //active展示具体那个页面,handle_active点击事件触发的
    link_chat () {
      window.open(
        `${process.env.VUE_APP_CHAT_URL}:3000/?role=${this.user_info.username}`,
        "_blank"
      );
    },
    async handle_confirm (item) {
      await API.USER.CheckListConfirm({ id: item.id });
      this.get_check_list();
      this.$el_message("已查看");
    },
    handle_logout () {
      this.$el_confirm("确定要退出登录吗？", () => {
        this.Logout();
        sessionStorage.clear();
        localStorage.clear();
      });
    },
    async get_list () {
      let res = await API.TASK.ListMessage();
      this.list = res || [];
    },
    //空域权限
    async get_arplist () {
      let res = await API.USER.airspaceqx();
      if (res) {
        this.airspace_list = res.data || [];
      }
    },
    //schedullist(调度审批列表)
    async get_schedullist () {
      let res = await API.USER.schedulqx();
      this.schedullist = res || [];
    },
    //check_list
    async get_check_list () {
      //vuex
      let id = this.user_info.uid;
      //传递过去的值是receiveId   返回过来的值赋值给Res
      let res = await API.USER.CheckList({ receiveId: id });
      //res赋值给check_list
      this.check_list = res || [];
    },
    start_timer () {
      this.timer = window.setInterval(() => {
        this.upDigitaltip();
        // 任务审批
        this.get_list();
        // 空域权限
        this.get_arplist();
        // 打卡提醒
        this.get_check_list();
        //调度审批
        this.get_schedullist();
      }, 3000);
    },
    stop_timer () {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },

    handle_show_approve (val) {
      this.curr_task = val;
      this.approve_form.visible = true;
    },
    //任务审批和空域权限的审批(用active来判断的，因为active不能同时显示两个)active == 0 任务审批 1 打卡提醒  2空域权限  3调度审批
    async handle_approve (val) {
      if (this.active == 0) {
        this.$el_confirm(
          "确定提交么？",
          async () => {
            let res = await API.TASK.Edit({
              id: this.curr_task.id,
              status: val,
              taskCateId: this.curr_task.taskCateId,
              approvalRemark: this.approve_form.approvalRemark,
            });
            if (res) {
              this.$el_message("审批成功");
            }
            this.get_list();
            this.upDigitaltip();
            this.approve_form.visible = false;
          },
          () => {
            this.approve_form.approvalRemark = null;
            this.approve_form.visible = false;
          }
        );
      } else if (this.active == 2) {
        let id = this.curr_task.id;
        this.$el_confirm(
          "确定提交么？",
          async () => {
            await API.USER.approval(id, val);
            this.$el_message("审批成功");
            this.get_list();
            this.upDigitaltip();
            this.approve_form.visible = false;
          },
          () => {
            this.approve_form.approvalRemark = null;
            this.approve_form.visible = false;
          }
        );
      } else if (this.active == 3) {
        let parameter = this.curr_task;
        this.$el_confirm(
          "确定提交么？",
          async () => {
            await API.USER.approvalqx({
              ...parameter,
              val,
            });
            this.$el_message("审批成功");
            this.get_list();
            this.upDigitaltip();
            this.approve_form.visible = false;
          },
          () => {
            this.approve_form.approvalRemark = null;
            this.approve_form.visible = false;
          }
        );
      }
    },
    //获取更多信息的tab页签头显示
    async arilist () {
      this.tagnamelist = [];
      let res = await API.USER.ManyBtnList();
      //messageStatus==1的时候就代表已订阅显示在页签头(push进tagnamelist)
      res.forEach((val) => {
        if (val.messageStatus == 1) {
          this.tagnamelist.push(val);
        }
      });
      if (this.tagnamelist.length > 0) {
        this.upDigitaltip();
        // 任务审批
        this.get_list();
        // 空域权限
        this.get_arplist();
        // 打卡提醒
        this.get_check_list();
        // // 获取数据列表
        // this.start_timer();
      }

      this.ismroedialog = false;
    },

    // 修复点击单位下拉框消失问题
    handleChange (val) {
      if (val == 0) {
        let _this = this;
        this.$refs.cascader.$refs.panel.$el.addEventListener(
          "click",
          function () {
            _this.$refs.dropdown.show();
          }
        );
      } else {
        this.$refs.dropdown.show();
        this.$refs.cascader.dropDownVisible = false;
      }
    },
  },

  destroyed () {
    this.stop_timer();
  },
  inject: ["handle_report_show"],
};
</script>

<style lang="scss" scoped>
.zhanshi {
  color: #ed5944 !important;
}

.safe-check {
  position: absolute;
  top: 39px;
  left: -6px;
  font-size: 20px;
  height: 50px;
}

//航线详情弹框
.routesdialog {
  width: 420px;
  height: 340px;
  // display: flex;
  position: absolute;
  top: 530px;
  right: 525px;
  background: url("~@/assets/messagetk/dyxx.png") no-repeat center center;
  background-size: cover;

  .zhanshi {
    color: #ed5944 !important;
  }

  .icon-zhanshi1 {
    color: #ed5944 !important;
  }

  .routesbox {
    .img-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .routestext {
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #dce9ff;
      text-align: center;
      font-weight: 400;
      margin: 8px 100px 0px 0px;
    }
  }

  .routeslistcss {
    display: flex;
    // text-align: center;
    width: 100%;
    height: 32px;
    line-height: 32px;

    .oneroutes {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      color: #dce9ff;
    }

    .tworoutes {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      text-align: left;
      letter-spacing: 0;
      font-weight: 400;
      color: #93f1f3;
    }
  }
}

// 更多弹框
.isdialogcss {
  width: 460px;
  height: 330px;
  // display: flex;
  position: absolute;
  padding: 20px;
  box-sizing: border-box;
  top: 200px;
  right: 645px;
  background: url("~@/assets/messagetk/dyxx.png") no-repeat center center;
  background-size: cover;

  .isdialogclose {
    .img-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .isdialogmes {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    text-align: center;
    font-weight: 400;
    margin: -15px 90px 15px 0px;
  }

  // 列表循环的
  .isdialoglist {
    display: flex;
    text-align: center;
    width: 100%;
    height: 32px;
    line-height: 32px;

    &:nth-child(odd) {
      background: #2b3857;
    }

    &:nth-child(even) {
      background: #1e2a45;
    }

    .manylistcss {
      display: flex;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #9bffff;
      letter-spacing: 0;

      // text-align: center;
      .rightmany {
        .el-checkbox.two {
          /deep/.el-checkbox__label {
            color: rgb(64, 158, 255);
          }
        }

        // /deep/.two.el-checkbox {
        //   .el-checkbox__inner {
        //     border: 1px solid red;
        //     background-color: red;
        //   }
        //   .el-checkbox__label {
        //     color: red;
        //   }
        //   // color: red;
        //   // background-color: red;
        // }
      }

      font-weight: 400;

      .leftmany {
        width: 250px;
        text-align: left;
        padding-left: 80px;
        box-sizing: border-box;

        .numbercss {
          font-size: 12px;
          display: inline-block;
          padding: 0.15em 0.4em;
          min-width: 8px;
          border-radius: 18px;
          background-color: #fa5151;
          color: #fff;
          line-height: 1.2;
        }
      }
    }
  }
}

.home-header-right_box {

  // position: absolute;
  // top: 0;
  // bottom: 0;
  // z-index: 9;
  // right: 0;
  .Barbox {
    top: 50px;
    right: -255px;
    position: absolute;
    // margin-right: 200px;
  }

  .right-list_box {
    height: 100%;
    display: flex;
    text-align: center;
    color: #fff;
    align-items: center;

    // margin: 6px 15px 0 0;
    .right_link {
      margin-right: 112px;
      display: flex;
      align-items: center;

      .link {
        margin-top: -11px;
        width: 133px;
        height: 29px;
        text-align: center;
        line-height: 29px;
        background: url("~@/assets/images/webhome/link-right.png") no-repeat;
        background-size: cover;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #e5f9ff;
        letter-spacing: 0;
        font-weight: 400;
      }

      .select {
        background: url("~@/assets/images/webhome/link-right-s.png") no-repeat;
      }
    }

    .list-item_box.chat {
      cursor: pointer;
      // &:hover {
      //   opacity: 0.7;
      // }
    }

    .list-item_box {
      display: flex;
      flex-direction: column;
      margin: 0px 0px 0 0;

      // margin-right: 16px;
      // &.duty:hover {
      //   cursor: pointer;
      //   // background: #00ffff;
      //   .icon {
      //     .iconfont {
      //       color: #19223d;
      //     }
      //   }
      //   .text {
      //     color: #19223d;
      //   }
      // }
      &::after {
        content: " ";
        display: block;
        width: 1px;
        height: 37px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(50%);
        background-image: linear-gradient(206deg,
            rgba(36, 146, 252, 0) 5%,
            #2492fc 56%,
            rgba(36, 146, 252, 0) 100%);
      }

      &:last-child::after {
        display: none;
      }

      .icon {
        .iconfont {
          font-size: 36px;
          color: #00e4ff;
        }

        img {
          width: 41px;
          height: 46px;
          margin-bottom: 3px;
        }
      }

      .text {
        font-family: Microsoft YaHei;
        font-size: 8px;
        letter-spacing: 0;
        text-align: center;
        line-height: 10px;
        font-weight: 400;
        color: #00e4ff;
        position: relative;

        .yuandian {
          width: 5px;
          height: 5px;
          padding: 2px;
          background-color: red;
          border-radius: 50%;
          font-family: NotoSansCJKsc-Regular;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          font-weight: 400;
          position: absolute;
          top: -41px;
          right: 10px;
          text-align: center;
          line-height: 16px;
        }
      }

      .icon-anquanfeihang {
        color: #00d2ff;
      }

      .icon-daka {
        color: #00d2ff;
      }

      .zhanshi {
        color: #ed5944;
      }

      .pingshi {
        color: #00d2ff;
      }

      .dep-info_box {
        display: flex;

        .dep-group_box {
          margin-right: 11px;

          &:last-child {
            margin-right: 0;
          }

          .dep-box {
            display: flex;
            align-items: center;

            .dep-title {
              font-family: Microsoft YaHei;
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              font-weight: 400;
              margin-right: 4px;

              .iconfont {
                font-size: 26px;
              }
            }

            .dep-name {
              font-family: MicrosoftYaHei-Bold;
              font-size: 16px;
              color: #00ffff;
              letter-spacing: 0;
              font-weight: 700;
            }
          }
        }
      }

      .wea-detail_box {
        display: flex;

        .wea-info-group_box {
          display: flex;
          flex-direction: column;
          margin-right: 23px;

          &:last-child {
            margin-right: 0px;
          }
        }

        .wea-info_box {
          height: 50%;
          display: flex;
          align-items: center;

          .wea-title {
            font-family: Microsoft YaHei;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 400;
            margin-right: 4px;
          }

          .wea-icon_box {
            margin-right: 4px;
            width: 30px;
            height: 30px;

            img {
              width: 100%;
              height: 100%;
            }

            .iconfont {
              font-size: 30px;
              color: #ffc736;
            }
          }

          .wea-value {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #00ffff;
            letter-spacing: 0;
            font-weight: 700;
          }
        }
      }

      .wea-speed {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #00ffff;
        letter-spacing: 0;
        font-weight: 700;
        height: 50%;
      }

      .wea-takeoff {
        font-family: YouSheBiaoTiHei;
        font-size: 20px;
        letter-spacing: 0;
        font-weight: 400;
        height: 50%;

        &._1 {
          color: #6aff64;
        }

        &._2 {
          color: red;
        }
      }

      .icon-box {
        display: flex;
        justify-content: center;
        height: 80%;
        position: relative;

        .iconfont {
          font-size: 36px;
          color: #00d2ff;
        }

        img {
          width: 34px;
          height: 34px;
        }
      }

      .title-box {
        font-family: Microsoft YaHei;
        font-size: 12px;
        color: #00d2ff;
        letter-spacing: 0;
        font-weight: 400;
      }

      .title-group_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Microsoft YaHei;
        font-size: 12px;
        color: #43f0df;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}

.badge {
  display: inline-block;
  padding: 0.15em 0.4em;
  min-width: 8px;
  border-radius: 18px;
  background-color: #fa5151;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  font-size: 12px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 0;
}

.duty-list-box {
  border: 1px solid red;
  width: 400px;
  height: 455px;
  background-image: linear-gradient(180deg, #161d2d 0%, #0c2e4f 100%);
  border: 1px solid #00ffff;
  box-shadow: 0 8px 16px 0 #101523;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 11px 9px;

  .hoverbgcolor {
    img:hover {
      cursor: pointer;
      background-color: rgb(11, 176, 176);
    }
  }

  .search-box {
    display: flex;
    margin-bottom: 15px;
    color: #08c2d1;
    justify-content: space-between;

    .detail {
      width: 45px;
      line-height: 40px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .icon-daka {
      color: #21e0e0;
      font-size: 28px;
      line-height: 40px;
      cursor: pointer;
      // &:hover {
      //   opacity: 0.7;
      // }
    }

    ::v-deep {

      .el-input,
      .el-cascader {
        width: 150px;
      }

      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #08c2d1;
      }

      .el-button {
        width: 114px;
        height: 40px;
        padding: 10px 20px;
        background: #129c9c;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        border: none;
      }
    }
  }

  .hd_box {
    margin-bottom: 7px;
    height: 20px;

    .tr {
      display: flex;

      .th {
        flex: 1;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #43c7f0;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }

  .bd_box {
    height: calc(100% - 20px);
    overflow: auto;

    .tr {
      display: flex;

      .td {
        flex: 1;
        font-family: Microsoft YaHei;
        font-size: 12px;
        color: #9bffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.group {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &:nth-last-of-type(odd) {
        background: #2b3857;
        box-shadow: inset 0 -1px 0 0 #275a61;
      }

      &:nth-last-of-type(even) {
        background: #1e2a45;
        box-shadow: inset 0 -1px 0 0 #275a61;
      }
    }
  }

  .bd_box_card {
    height: calc(100% - 80px);
  }
}
</style>

<style lang="scss">
.el-dropdown-menu {
  padding: 0;
  border: none;

  .message-box {
    width: 400px;
    height: 453px;
    background-color: #161d2d;
    box-sizing: border-box;
    border: 1px solid #00ffff;
    border-radius: 4px;
    padding-bottom: 10px;

    .tabs {
      display: flex;
      justify-content: space-between;

      i {
        margin: auto;
        color: #2edfff;
      }

      .tabBox {
        width: 300px;
        height: 47px;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        .tab-box {
          width: 100px;
          height: 47px;
          flex-shrink: 0;
          // flex: 1;
          text-align: center;
          line-height: 47px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          cursor: pointer;
          border-radius: 4px 0px 0px 0px;
          font-size: 16px;
          color: #92d9ff;
          font-weight: 700;
          position: relative;

          &:hover {
            color: #08c2d1;
          }

          .tgml {
            position: absolute;
            top: 0px;
            right: 5px;
            font-size: 12px;
            display: inline-block;
            padding: 0.15em 0.4em;
            min-width: 8px;
            border-radius: 18px;
            background-color: #fa5151;
            color: #fff;
            line-height: 1.2;
            font-weight: 400;
          }
        }
      }

      .tab-box.tabMore {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #08c2d1;
        font-weight: 400;
        margin: auto;
        cursor: pointer;
        position: relative;

        .btnMorecss {
          position: absolute;
          top: -20px;
          right: -10px;
          font-size: 12px;
          display: inline-block;
          padding: 0.15em 0.4em;
          min-width: 8px;
          border-radius: 18px;
          background-color: #fa5151;
          color: #fff;
          line-height: 1.2;
          font-weight: 400;
          margin-top: 5px;
        }
      }

      .tab-box.active {
        display: inline-block;
        background: linear-gradient(#08c2d1, #08c2d1) no-repeat;
        background-size: 65% 3px;
        background-position: 18px 2.3em;
        font-weight: bold;
        color: #00ffff;
      }
    }

    .table {
      height: calc(100% - 47px);
      padding: 0 10px;
      position: relative;
    }

    .tb-hd {
      display: flex;

      .td {
        flex: 1;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #43c7f0;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
      }
    }

    .tb-bd {
      width: 100%;
      overflow: auto;
      height: calc(100% - 30px);

      .tr {
        display: flex;
        min-height: 59px;
        align-items: center;

        &:nth-child(odd) {
          background: #2b3857;
        }

        &:nth-child(even) {
          background: #1e2a45;
        }

        .zhtd {
          width: 126px;
        }

        .td {
          flex: 1;
          text-align: center;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #9bffff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          word-break: break-all;

          &.ope {
            color: #6aff64;
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-dialog {
  background: transparent;
  background-image: url("~@/assets/images/approve.png");
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

.el-dialog__title {
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.el-dialog__header {
  text-align: left;
  margin-left: 100px;
}

.el-textarea__inner {
  color: #ffff;
  background-color: #000000;
  border: 1px solid #08c2d1;
}

.el-form-item__label {
  color: #fff;
}

// .el-checkbox__input.is-checked + .el-checkbox__label {
//   color: #000000;
// }
// .el-checkbox__input.is-checked .el-checkbox__inner {
//   border: 1px solid #000;
//   background-color: #000000;
// }
.yingimg {
  width: 24px !important;
  height: 24px !important;
  margin-bottom: 0px !important;
}

.Popout {
  // margin-top: 23px;
  width: 102px;
  height: 58px;
  position: relative;
  cursor: pointer;

  .Popout_bj {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 39px;
    width: 102px;
    background-image: url('~@/assets/zong_lan_ye_img/gongneng_v2.png');
    background-size: 100% 100%;
    // z-index: 2;
  }

  .icon {
    width: 100%;
    padding-top: 6px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    padding: 0 0 0 17px;
    box-sizing: border-box;
  }

  &:hover {
    .Popout_bj {
      background-image: url('~@/assets/zong_lan_ye_img/gongneng_bj.png');
    }

    .fontFive {
      color: #C4E7FF;
    }
  }
}
</style>