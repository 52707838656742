<template>
  <div class="cont">
    <div class="mount">
      <div class="mmmmm">
        <div class="top"></div>
        <div class="down"></div>
        <div ref="guaZai" class="div" @scroll="GZscroll">
          <div class="xu shang"></div>
          <div
            class="info"
            v-for="(item, index) in list"
            :id="'in' + item.id"
            :key="item.id"
            :class="{ active: activeIndex === index, info1: index === 0 }"
            @click="showInfo(item, $event)"
          >
            <div class="text">
              <h2>{{ item.name }}</h2>
              <p>{{ item.type }}</p>
            </div>
            <div class="img">
              <img :src="item.src" alt="" />
            </div>
          </div>
          <div class="xu xia"></div>
          <!-- <div class="xu xia"></div> -->
        </div>
      </div>
    </div>
    <div class="uav_img">
      <div class="img">
        <img :src="infoList.src" alt="" />
      </div>
    </div>
    <div class="uav_info">
      <div class="title">入云龙2</div>
      <div class="info">
        <div class="info-text">
          <div>
            <p>机翼类型</p>
            <span>六旋翼</span>
          </div>
          <div>
            <p>高度</p>
            <span>510mm</span>
          </div>
          <div>
            <p>轴距</p>
            <span>1550mm</span>
          </div>
          <div>
            <p>净重</p>
            <span>6.26kg</span>
          </div>
        </div>
        <div class="info-img">
          <img src="~@/assets/new_yingji/UAV.png" alt="" />
        </div>
      </div>
      <div class="mount_info" v-show="show">
        <h3>{{ infoList.name }}</h3>
        <div class="mount_text">
          <div>
            <p>尺寸</p>
            <span>{{ infoList.chicun }}</span>
          </div>
          <div>
            <p>重量</p>
            <span>{{ infoList.zhongliang }}</span>
          </div>
          <div>
            <p>工作速度</p>
            <span>{{ infoList.shudu }}</span>
          </div>
          <div>
            <p>最大音量</p>
            <span>{{ infoList.yinliang }}</span>
          </div>
          <div>
            <p>有效喊话距离</p>
            <span>{{ infoList.haihua }}</span>
          </div>
          <div>
            <p>角度调节范围</p>
            <span>{{ infoList.jiaodu }}</span>
          </div>
          <div>
            <p>功率</p>
            <span>{{ infoList.gonglv }}</span>
          </div>
        </div>
      </div>
      <div
        v-show="!show"
        style="text-align: center; margin-top: 20px; font-size: 20px"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: '破窗发射器',
          type: 'SN_000185',
          src: require('@/assets/new_yingji/pcq.svg'),
          id: 1,
          chicun: '225*272*221mm',
          zhongliang: '1kg',
          shudu: '1km/s',
          yinliang: '140dB',
          hanhua: '720m',
          jiaodu: '0-90°',
          gonglv: '100W',
        },
        {
          name: '射击枪',
          type: 'MMC_20230215',
          src: require('@/assets/new_yingji/sjq.svg'),
          id: 2,
          chicun: '272*221mm',
          zhongliang: '2kg',
          shudu: '2km/s',
          yinliang: '220dB',
          hanhua: '520m',
          jiaodu: '0-360°',
          gonglv: '100W',
        },
        {
          name: '喊话器',
          type: 'SN_000019',
          src: require('@/assets/new_yingji/hhq.svg'),
          id: 3,
          chicun: '125*72*121mm',
          zhongliang: '5kg',
          shudu: '10m/s',
          yinliang: '60dB',
          hanhua: '720m',
          jiaodu: '0-90°',
          gonglv: '40W',
        },
        {
          name: '网枪',
          type: 'MMC_20230215',
          src: require('@/assets/new_yingji/wq.svg'),
          id: 4,
          chicun: '225*32*121mm',
          zhongliang: '0.5kg',
          shudu: '20m/s',
          yinliang: '40dB',
          hanhua: '120m',
          jiaodu: '0-90°',
          gonglv: '18W',
        },
        {
          name: '相机',
          type: 'MMC_20230215',
          src: require('@/assets/new_yingji/xj2.svg'),
          id: 5,
          chicun: '225*32*121mm',
          zhongliang: '0.5kg',
          shudu: '20m/s',
          yinliang: '40dB',
          hanhua: '120m',
          jiaodu: '0-90°',
          gonglv: '18W',
        },
        {
          name: '高清变焦相机',
          type: 'MMC_20230215',
          src: require('@/assets/new_yingji/gqbjxj.svg'),
          id: 6,
          chicun: '225*32*121mm',
          zhongliang: '0.5kg',
          shudu: '20m/s',
          yinliang: '40dB',
          hanhua: '120m',
          jiaodu: '0-90°',
          gonglv: '18W',
        },
      ],
      infoList: {},
      show: true,
      activeIndex: 0,
      scrollHeight: [],
    }
  },
  methods: {
    showInfo(item, event) {
      this.list.map((info,index) => {
        if (info.id === item.id) {
          this.activeIndex = index
          let jump = document.getElementById('in' + item.id)
          this.$refs.guaZai.scrollTop = jump.offsetTop - 200
          console.log(this.$refs.guaZai.scrollTop, jump)
          this.infoList = info
          this.show = true
        }
      })
    },
    getScrollHeight() {
      this.$nextTick(() => {
        this.scrollHeight = []
        this.list.map((item) => {
          this.scrollHeight.push(
            document.getElementById('in' + item.id).offsetTop - 20
          )
        })
      })
    },

    GZscroll(e) {
      let height = e.target.scrollTop + 160;
      if (
        height + 40 <
        this.scrollHeight[0]
      ) {
        this.activeIndex = 0
      } else {
        this.scrollHeight.map((item, index) => {
          if (height >= item) {
            this.activeIndex = index + 1
            console.log(height,item)
          }
        })
      }
    },
  },

  watch: {},
  created() {
    this.infoList = this.list[0]
    this.getScrollHeight()
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.cont {
  margin-top: 76px;
  height: calc(100% - 88px);
  display: flex;
  align-items: center;
  color: #fff;

  .mount {
    width: 500px;
    height: calc(100% - 188px);
    margin-left: 20px;

    .mmmmm {
      width: 100%;
      height: 100%;
      position: relative;
      // background-color: #fff;

      .top,
      .down {
        // width: 500px;
        // height: 34%;
        position: absolute;
        z-index: 1;
      }
      .top {
        top: 0;
        // background-color: #ad81812d;
      }
      .down {
        bottom: 0;
        // background-color: #b1e4c44b;
      }
    }
    .div {
      height: 100%;
      position: relative;
      transform-origin: -600px -40px;
      overflow-y: auto;
      overflow-x: hidden;
      // background-color: #fff;
      padding-bottom: 140px;
      padding-top: 80px;
      box-sizing: border-box;

      .info {
        width: 500px;
        height: 166px;
        margin-top: -40px;
        background: url('~@/assets/new_yingji/xu.png') no-repeat;
        display: flex;
        align-items: center;
        // position: absolute;
        // top: 0;
        // left: 0;

        .text {
          padding: 1.5rem 0 0 2rem;
          width: 55%;
          transform: skew(0deg, -10deg);

          h2 {
            font-family: 'YouSheBiaoTiHei';
          }

          p {
            font-size: 12px;
            line-height: 30px;
          }
        }

        .img {
          width: 120px;
          height: 80px;

          img {
            width: 50%;
            height: 50%;
          }
        }

        // &:hover {
        //   height: 190px;
        //   background: url('~@/assets/new_yingji/shi.png') no-repeat;
        //   cursor: pointer;

        //   .text {
        //     width: 65%;
        //   }
        // }
      }
      .info1 {
        margin-top: -42px;
      }
      .active {
        height: 190px;
        background: url('~@/assets/new_yingji/shi.png') no-repeat;
        cursor: pointer;

        .text {
          width: 65%;
        }
      }
      .xu {
        width: 480px;
      }
      .shang {
        height: 210px;
        margin-left: -10px;
        background: url('~@/assets/new_yingji/shang.png') no-repeat;
        background-size: 101% 142%;
        background-position-x: -54px;
        background-position-y: -14px;
      }
      .xia {
        height: 212px;
        margin-top: -114px;
        margin-left: -10px;
        background: url('~@/assets/new_yingji/xia.png') no-repeat;
        background-size: 100% 140%;
      }
    }
  }
  // 滚动条样式
  .div::-webkit-scrollbar {
    width: 0px;
    background-color: #333c57;
    height: 8px;
  }

  .uav_img {
    margin: 10rem 2.5rem 0 3.4rem;
    flex: 1;
    height: 389px;

    .img {
      margin: 0 auto;
      text-align: center;
      width: 80%;
      height: 80%;
      // background: url('~@/assets/new_yingji/UAV.png') no-repeat;
      background-size: 100% 100%;
      img {
        // width: 100%;
        height: 100%;
      }
    }
  }

  .uav_info {
    margin-left: 80px;
    width: 464px;
    height: 692px;
    background: url('~@/assets/new_yingji/you.png') no-repeat;
    // padding: 30px;

    .title {
      text-align: right;
      padding-top: 2rem;
      padding-right: 3rem;
      font-family: 'YouSheBiaoTiHei';
      font-size: 24px;
    }

    .info {
      margin-top: 20px;
      padding-left: 30px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 170px;

      .info-text {
        width: 40%;

        div {
          display: flex;
          align-items: center;
          height: 30px;
          margin: 8px;
          background: url('~@/assets/new_yingji/bj_text.png');

          p {
            text-indent: 1rem;
            margin-right: 20px;
            font-family: 'YouSheBiaoTiHei';
          }

          span {
            font-size: 14px;
            color: #e2e2e2;
          }
        }
      }

      .info-img {
        // width: 40%;
        margin-left: 1rem;

        img {
          width: 215px;
          height: 112px;
        }
      }
    }

    .mount_info {
      h3 {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .mount_text {
        padding-left: 30px;
        box-sizing: border-box;

        //   display: flex;
        //   align-items: center;
        div {
          display: flex;
          align-items: center;
          height: 30px;
          margin: 8px;
          background: url('~@/assets/new_yingji/bj_text.png');
          background-size: 100% 100%;

          p {
            text-indent: 1rem;
            margin-right: 20px;
            font-family: 'YouSheBiaoTiHei';
          }

          span {
            font-size: 14px;
            color: #e2e2e2;
          }
        }
      }
    }
  }
}
</style>