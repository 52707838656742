<template>
  <div class="eagleStrike pa wih80 ht60">
    <Header title="联击" />
    <Content />
  </div>
</template>

<script>

// 新首页
import Header from "@/components/eagleStrike/header/index-new.vue";
import Content from "@/components/eagleStrike/new-home/index.vue";
export default {
  components: {
    Header,
    Content,
  },
  data() {
    return {}
  },
  created() {
    document.title = '联击'
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.eagleStrike {
  width: 100vw;
  height: 100%;
  background: url("~@/assets/new_yingji/home.png") no-repeat;
  background-size: 100% 100%;
  overflow-y: auto;
  z-index: 11;
}
</style>