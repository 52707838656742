<!-- 鹰击头部 -->
<template>
  <div>
    <div class="cpt-home_header">
      <div class="home-header_inner">
        <!-- <HeaderLeft /> -->
        <div class="header-bg_box">
          <div class="yingji" @click="handleClick">
            <!-- <img
              alt=""
              src="~@/assets/img/ji.png"
              style="width: 100%; height: 100%"
            /> -->
          </div>
          <div class="flex gundongtiao">
            <div class="flex tiao" style="align-items: center">
              <div class="gundong">
                <ul class="ul">
                  <li class="tap" @mouseover="tapyi(item.id)" @mouseout="tapyichu" v-for="item in optionsLeft"
                    :key="item.id" @click="tap_click(item)">
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="nest_header_box_left">
              <img class="w35 h35 ml21" :src="weatherIcon" alt />
              <div>
                <div style="color: #00ffff" class="ml36">
                  <div>{{ weather_data.windDirection }}风</div>
                  {{ weather_data.minTemp || 0 }}°~{{
                    weather_data.maxTemp || 0
                  }}°
                </div>
              </div>
              <div></div>
              <div
                class="noFlight ml28"
                v-if="weather_data && weather_data.flyStatus == 0"
              >
                不宜起飞
              </div>
              <div class="isFlight ml28" v-else>适合起飞</div>
              <div class="date-box cf ml30" style="color: #00ffff">
                <div class="yyyy">{{ date }}</div>
                <div></div>
                <div class="week-box jcsb">
                  <div class="week fw700">{{ week }}</div>
                  <div class="hhmm">{{ time }}</div>
                </div>
              </div>
            </div> -->
            <HeaderRight :data="user" class="jl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLeft from '@/components/header-left/index_v5.vue'
// import HeaderRight from "@/components/header-right";
import HeaderRight from '@/components/header-right/index_v5.vue'
//箭头
import zuo from '@/assets/newImage/zuojian.png'
import zuoJin from '@/assets/newImage/zuojianJin.png'
import you from '@/assets/newImage/youjian.png'
import youJin from '@/assets/newImage/youjianJin.png'
//tap标签背景
import tapLan from '@/assets/newImage/taplan.png'
import tapJin from '@/assets/newImage/tapJin.png'
// bus
import Bus from '@/assets/ligature'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

// 引入 天气状况
// import tianqi from "@/components/command/header/tianqi/index.vue";
export default {
  props: {
    title: {
      type: String,
      default: () => '标题',
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    flag: {
      type: Number | String,
      default: () => 1,
    },
  },
  data () {
    return {
      zuo: zuo,
      you: you,
      weather: {},
      now: null,
      weatherIcon: null,
      weather_data: {
        weather: '晴',
        icon: require(`@/assets/images/weather/icon/day/01.png`),
        temperature: 0,
        humidity: 0,
        windPower: '',
        windSpeed: '',
        minTemp: '',
        maxTemp: '',
        windDirection: '东北',
      },
      ws: '',
      // tap标签引用的是两个路径地址,这样可以动态加载图片
      tap: tapLan,
      tapJin: tapJin,
      imgId: '',
      week_map: {
        0: '天',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
      },
      optionsLeft: [
        { id: 10, title: '首页', path: '/eagleStrike' },
        { id: 1, title: '交警实战', path: '/accident?page=1', type: 'traffic' },
        {
          id: 2,
          title: '巡特警实战',
          path: '/accident?page=0',
          type: 'patrol',
        },
        { id: 3, title: '食药环实战', path: '/accident?page=2', type: 'food' },
        { id: 4, title: '网安实战', path: '/accident?page=3', type: 'net' },
        { id: 5, title: '水警实战', path: '/accident?page=4', type: 'water' },
        { id: 6, title: '技侦实战', path: '/accident?page=6', type: 'crimes' },
      ],
    }
  },
  components: { HeaderLeft, HeaderRight },
  computed: {
    date () {
      let { now } = this
      return now ? `${now.format('YYYY/MM/DD')}` : '0000/00/00'
    },
    week () {
      let { now, week_map } = this
      return now ? `周${week_map[now.day()]}` : '周N/A'
    },
    time () {
      let { now } = this
      return now ? `${now.format('HH:mm')}` : '00:00'
    },
    ...mapGetters(['user_info']),
  },
  // inject: ["g_weather"],
  mounted () {
    const data = new Date()
    let time = data.getHours()
    this.ws = new WebSocket(process.env.VUE_APP_WS_URL_ALARM)
    let { appid, username } = JSON.parse(localStorage.getItem('user_info')).data
    let token = JSON.parse(localStorage.getItem('user_info')).data[
      'mmc-identity'
    ]
    this.ws.onopen = () => {
      this.ws.send(
        JSON.stringify({
          type: 100,
          systemCode: 'mmc',
          state: 1,
          username,
          token,
          appId: appid,
        })
      )
    }
    this.ws.onmessage = (e) => {
      let metadata = JSON.parse(e.data)
      // console.log(metadata, '无人机原始数据，可能引起地图压力过大7');
      if (metadata.msgnum === 4131) {
        let dataItem = metadata.data.filter((item) => {
          return item.id == 8
        })
        this.weather_data.minTemp = dataItem[0].minTemp
        this.weather_data.maxTemp = dataItem[0].maxTemp
        this.weatherIcon = require(`@/assets/images/weather/icon/${time >= 18 ? 'night' : 'day'
          }/${dataItem[0].weatherCode}.png`)
        // this.weatherIcon = require(`@/assets/images/weather/icon/${
        //   time >= 18 ? "night" : "day"
        // }`);
        let v = dataItem[0].windDirection
        // console.log("wea--v:", v);
        if (v == 0) {
          this.weather_data.windDirection = '北'
        } else if (v > 0 && v < 90) {
          this.weather_data.windDirection = '东北'
        } else if (v == 90) {
          this.weather_data.windDirection = '东'
        } else if (v > 0 && v < 90) {
          this.weather_data.windDirection = '东北'
        } else if (v > 90 && v < 180) {
          this.weather_data.windDirection = '东南'
        } else if (v == 270) {
          this.weather_data.windDirection = '西'
        } else if (v > 270 && v < 360) {
          this.weather_data.windDirection = '西北'
        }
      }
    }
    setInterval(() => {
      this.now = dayjs()
    }, 500)
  },
  beforeDestroy () {
    this.ws && this.ws.close()
    this.ws = ''
  },
  methods: {
    // 箭头事件
    zuoyi () {
      this.zuo = zuoJin
    },
    zuoyichu () {
      this.zuo = zuo
    },
    youyi () {
      this.you = youJin
    },
    youyichu () {
      this.you = you
    },
    tapyi (id) {
      this.imgId = id
    },
    tapyichu () {
      this.imgId = ''
    },
    // 箭头跳转,思路,输出数组第一个对象,然后push数组最后面,右箭头反之
    zuotiao () {
      const allLength = this.optionsLeft.length * 134
      const boxLength = document.querySelector('.gundong').clientWidth
      if (allLength < boxLength) return
      const listEl = document.querySelector('.ul')
      const leftMove = parseInt(window.getComputedStyle(listEl, null)?.left)
      if (leftMove + boxLength < boxLength) {
        // 滚到
        listEl.style.left = '0px'
      } else if (leftMove < 0) {
        listEl.style.left = leftMove + 134 + 'px'
      }
      //   let a = JSON.parse(JSON.stringify(this.optionsLeft));
      //   let b = a.splice(0, 1);
      //  if(b[0].id == 1){
      //    return
      //  }else{
      //   a.push(b[0]);
      //   this.optionsLeft = a;
      //  }
    },
    youtiao () {
      const allLength = this.optionsLeft.length * 134
      const boxLength = document.querySelector('.gundong').clientWidth
      if (allLength < boxLength) return
      const listEl = document.querySelector('.ul')
      const leftMove = Math.abs(
        parseInt(window.getComputedStyle(listEl, null)?.left)
      )
      console.log(leftMove, allLength)
      if (leftMove + boxLength >= allLength) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 134) + 'px'
      }
      // let a = JSON.parse(JSON.stringify(this.optionsLeft));
      // let b = a.splice(a.length - 1, 1);
      // if(b[0].id == 1){
      //   return
      // }else{
      //   a.unshift(b[0]);
      // this.optionsLeft = a;
      // }
    },

    // tap标签跳转功能
    tap_click (item) {
      let eagleCodeList = this.user_info['eagleCodeList']
      console.log(eagleCodeList, 'eagleCodeList???', item)
      if (item.type) {
        if (
          eagleCodeList.length == 0 ||
          eagleCodeList.indexOf(item.type) == '-1'
        ) {
          return this.$message.info('暂无操作权限!')
        }
      }
      if (item && item.path) {
        window.open('#' + item.path, '_blank')
        // this.$router.push(item.path)
      }
    },
    // 首页跳转功能
    handleClick () {
      if (this.$route.path.includes('1027')) {
        let { href } = this.$router.resolve({ path: '/home1027' })
        window.open(href, '_self')
        setTimeout(() => {
          this.$router.go(0)
        }, 100)
      } else {
        let { href } = this.$router.resolve({ path: '/home' })
        window.open(href, '_self')
        setTimeout(() => {
          this.$router.go(0)
        }, 100)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-home_header {
  height: 72px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .home-header_inner {
    height: 100%;
    position: relative;

    .header-bg_box {
      min-width: 1920px;
      width: 100vw;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      background: center 0px url('~@/assets/new_yingji/headerNew.png') no-repeat;
      background-size: 100% 100%;

      .yingji {
        margin: 5px 0 0 3%;
        min-width: 7%;
        height: 80%;
        cursor: pointer;
        // background: #fff;
      }

      .gundongtiao {
        margin: 0 0.5% 0 5.5%;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        // width: 100%;
        // padding: 0 20px;

        .tiao {
          flex: 1;

          .gundong {
            // tap标签栏区域
            position: relative;
            width: 1310px;
            height: 39px;
            // border: 1px solid red;
            margin: 0 6px 9px 6px;
            padding: 0;
            overflow: hidden;

            ul {
              position: absolute;
              left: 0;
              list-style: none;
              display: flex;
              overflow: hidden;

              .tap {
                width: 170px;
                height: 46px;
                position: relative;
                text-align: center;
                margin: 0 0px 0 0;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  font-size: 24px;
                  font-family: YouSheBiaoTiHei;
                  color: #ffffff;
                  line-height: 30px;
                  background: linear-gradient(180deg,
                      #ffffff 0%,
                      #ffffff 36%,
                      #7897dc 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
            }
          }

          .gundong::-webkit-scrollbar {
            width: 0 !important;
          }
        }

        .jl {
          margin: 0 0 0 15px;
        }
      }

      .zuojian {
        // 左箭头
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 0 8px 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .youjian {
        // 右箭头
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 0 8px 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.jianju {
  margin: 0 0px 0 2%;
}

.flex {
  display: flex;
}

.nest_header_box_left {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  height: 50px;

  .isFlight {
    font-family: YouSheBiaoTiHei;
    font-size: 40px;
    color: #6aff64;
    letter-spacing: 0;
    font-weight: 400;
  }

  .noFlight {
    font-family: YouSheBiaoTiHei;
    font-size: 40px;
    color: #ffc15b;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.landi {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -99;

  img {
    width: 100%;
    height: 100%;
  }
}

.tap {
  background-image: url('~@/assets/new_yingji/btn_lan.png');
  background-size: 100% 100%;
  line-height: 29px;
}

.tap:nth-of-type(1) {
  background-image: url('~@/assets/new_yingji/btn_jin.png');
}

.tap:hover {
  background-image: url('~@/assets/new_yingji/btn_jin.png');
  background-size: 100% 100%;
  cursor: pointer;
}

.jj {
  margin: 0 0 0 12px;
}
</style>